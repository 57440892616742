import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import WorkCard from '../components/Work/WorkCard'
import Layout from '../components/Layout'
import HeaderPrimary from '../components/Header/HeaderPrimary'
import Footer from '../components/Footer'

class WorkTypeTemplate extends React.Component {
  render() {
    const { location, data, pageContext } = this.props
    const { title } = pageContext
    return (
      <Layout location={location}>
        <SEO
          title={title || 'Work'}
          description={title || ''}
          location={location}
        />
        <HeaderPrimary />
        <div className="pt-60vh">
          <div className="js-scroll-target js-transition-link-target">
            <div className="px-gs">
              {title && <h1 className="mb-8 lg:mb-10 xl:mb-12">{title}</h1>}
              <div className="work-grid">
                {data.allPrismicWork.edges.map((item, index) => {
                  return (
                    <WorkCard
                      key={index}
                      uid={item.node.uid}
                      section={pageContext.uid}
                      title={item.node.data.title}
                      cardSize={'Half-Width'}
                      cardDisplay={item.node.data.card_display}
                      cardDotColour={item.node.data.card_dot_colour}
                      cardVideoUrl={item.node.data.card_video_url}
                      cardVideoPoster={item.node.data.card_video_poster}
                      landscapeCardImage={item.node.data.card_image_landscape}
                      portraitCardImage={item.node.data.card_image_portrait}
                    />
                  )
                })}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </Layout>
    )
  }
}

WorkTypeTemplate.propTypes = {
  location: PropTypes.object.isRequired,
}

export const workTypeTemplateQuery = graphql`
  query filteredWorkItems($uid: String) {
    allPrismicWork(
      filter: {
        data: { types: { elemMatch: { type: { uid: { eq: $uid } } } } }
      }
    ) {
      edges {
        node {
          uid
          data {
            title
            card_display
            card_video_url
            card_video_poster {
              url
            }
            card_dot_colour
            card_image_portrait {
              alt
              fluid(
                imgixParams: { q: 80 }
                srcSetBreakpoints: [640, 768, 1024, 1366]
              ) {
                srcSetWebp
                srcWebp
                sizes
                src
                srcSet
                base64
                aspectRatio
              }
            }
            card_image_landscape {
              alt
              fluid(
                imgixParams: { q: 80 }
                srcSetBreakpoints: [640, 768, 1024, 1366]
              ) {
                srcSetWebp
                srcWebp
                sizes
                src
                srcSet
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default WorkTypeTemplate
